import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import PrivacyPolicy from "./views/pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./views/pages/Terms&Conditions/Terms&Conditions";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages

const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const VerifyEmail = React.lazy(() =>
  import("./views/pages/verifyEmail/verifyEmail")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/forgotPassword")
);
const About = React.lazy(() => import("./views/pages/about/About"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route
          exact
          path="/register"
          name="Register Page"
          element={<Register />}
        />
        <Route
          exact
          path="/email-verification"
          name="Email Verification Page"
          element={<VerifyEmail />}
        />
        <Route
          exact
          path="/forgot-password"
          name="Password Reset Page"
          element={<ForgotPassword />}
        />
        <Route
          exact
          path="/cms/about-us"
          name="About Page"
          element={<About />}
        />
        <Route
          exact
          path="/cms/privacy-policy"
          name="Privacy Policy"
          element={<PrivacyPolicy />}
        />
        <Route
          exact
          path="/cms/terms&conditions"
          name="Terms And Conditions"
          element={<TermsAndConditions />}
        />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />

        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  );
};

export default App;
