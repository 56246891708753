import React, { useState, useEffect } from "react";
import axios from "axios";
import { admin_url } from "../../../config/config";

export default function TermsAndConditions() {
  const [termsAndConditions, setTermsAndConditions] = useState([]);

  useEffect(() => {
    axios.get(admin_url + "/cms/get").then((response) => {
      setTermsAndConditions(response.data.data.termsAndConditions);
    });
  }, []);

  return (
    <>
      <h1 className="cms">Terms & Conditions</h1>
      <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
        {termsAndConditions}
      </div>
    </>
  );
}
